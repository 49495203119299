<div class="contain1">
  <div class="footer position-relative z-3">
    <div class="footer-wrap d-flex flex-column align-items-start">
      <p
        class="footer-text d-flex flex-column justify-content-center align-items-start"
      >
        <span class="fw-bold">Have an idea?</span>
        <a class="footer-contact-link fw-bold" routerLink="/contact-us">
          Tell us about it
        </a>
      </p>
      <div class="input-areas">
        <div class="news_title_area">
          <p class="news_subtitle">Join Nexotips</p>
          <p class="news_title">Newsletter</p>
        </div>
        <form
          [formGroup]="newsLetterForm"
          (ngSubmit)="onSubmit()"
          id="subscribe"
          class="d-flex flex-md-row flex-column"
        >
          <div class="form-block">
            <div class="form-contact">
              <div class="intake-form-input">
                <div class="intake-form_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                  >
                    <path
                      d="M19 4h-4V3c0-1.654-1.346-3-3-3S9 1.346 9 3v1H5C2.243 4 0 6.243 0 9v10c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5V9c0-2.757-2.243-5-5-5Zm-8-1a1.001 1.001 0 0 1 2 0v3h-2.001V3Zm11 16c0 1.654-1.346 3-3 3H5c-1.654 0-3-1.346-3-3V9c0-1.654 1.346-3 3-3h4c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2h4c1.654 0 3 1.346 3 3v10ZM9.5 12.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm7.468 7.251a1 1 0 1 1-1.937.498C14.7 18.967 13.397 18 11.999 18s-2.702.967-3.032 2.249a1 1 0 0 1-1.937-.498C7.589 17.577 9.678 16 11.998 16s4.409 1.577 4.968 3.751Z"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  formControlName="name"
                  required
                />
              </div>
              <div
                class="form-error"
                *ngIf="
                  newsLetterForm.controls['name'].invalid &&
                  newsLetterForm.controls['name'].touched
                "
              >
                <div
                  class="error-message"
                  *ngIf="newsLetterForm.controls['name'].errors?.['required']"
                >
                  Name is required
                </div>
                <div
                  class="error-message"
                  *ngIf="newsLetterForm.controls['name'].errors?.['minlength']"
                >
                  Name must be at least 3 characters
                </div>
              </div>
            </div>
            <div class="form-contact">
              <div class="intake-form-input">
                <div class="intake-form_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                  >
                    <path
                      d="M19 1H5a5.006 5.006 0 0 0-5 5v12a5.006 5.006 0 0 0 5 5h14a5.006 5.006 0 0 0 5-5V6a5.006 5.006 0 0 0-5-5ZM5 3h14a3 3 0 0 1 2.78 1.887l-7.658 7.659a3.007 3.007 0 0 1-4.244 0L2.22 4.887A3 3 0 0 1 5 3Zm14 18H5a3 3 0 0 1-3-3V7.5l6.464 6.46a5.007 5.007 0 0 0 7.072 0L22 7.5V18a3 3 0 0 1-3 3Z"
                    ></path>
                  </svg>
                </div>
                <input
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  formControlName="email"
                  required
                />
              </div>
              <div
                class="form-error"
                *ngIf="
                  newsLetterForm.controls['email'].invalid &&
                  newsLetterForm.controls['email'].touched
                "
              >
                <div
                  class="error-message"
                  *ngIf="newsLetterForm.controls['email'].errors?.['required']"
                >
                  Email is required
                </div>
                <div
                  class="error-message"
                  *ngIf="newsLetterForm.controls['email'].errors?.['email']"
                >
                  Invalid email address
                </div>
              </div>
            </div>
          </div>
          <div class="form-button">
            <button type="submit" class="form-button-link">Join</button>
          </div>
        </form>
      </div>
      <div
        class="footerinfo d-flex align-items-center justify-content-between flex-wrap w-100"
      >
        <div class="footer-info-text d-flex flex-column align-items-start">
          <a href="mailto:vocation@nexotips.com" class="footer-email"
            >contact&#64;nexotips.com
          </a>
          <p class="footer-address">
            221-222 , Amby Valley Arcade, Uttran,
            <br />
            Mota Varachha, Surat, 394105, India.
          </p>
          <div class="d-flex gap-4">
            <a
              class="footer-policy"
              href="https://blog.nexotips.com"
              target="_blank"
            >
              Blog</a
            >
            <a class="footer-policy" [routerLink]="['/privacy-policy']"
              >Privacy Policy</a
            >
          </div>
        </div>
        <div class="footer-info-link d-flex flex-wrap">
          <a
            class="d-flex gap-2 footer-link-1 fw-light align-items-center"
            href="https://www.facebook.com/nexotipstech"
            target="_blank"
          >
            <img
              src="./../../../assets/icons/facebook.png"
              alt="facebook"
              class="footer-icon"
            />
            <p class="mb-0">Facebook</p>
          </a>

          <a
            class="d-flex gap-2 footer-link-2 fw-light align-items-center"
            href="https://www.instagram.com/nexotips/"
            target="_blank"
          >
            <img
              src="./../../../assets/icons/instagram.png"
              alt="instagram"
              class="footer-icon"
            />
            <p class="mb-0">Instagram</p>
          </a>
          <a
            class="d-flex gap-2 footer-link-1 fw-light align-items-center"
            href="https://blog.nexotips.com"
            target="_blank"
          >
            <img
              src="./../../../assets/icons/blog.png"
              alt="blog"
              class="footer-icon"
            />
            <p class="mb-0">Blog</p>
          </a>
          <a
            class="d-flex gap-2 footer-link-2 fw-light align-items-center"
            href="https://in.linkedin.com/company/nexotips"
            target="_blank"
          >
            <img
              src="./../../../assets/icons/linkedin.png"
              alt="linkedin"
              class="footer-icon"
            />
            <p class="mb-0">LinkedIn</p>
          </a>
          <a
            class="d-flex gap-2 footer-link-1 fw-light align-items-center"
            href="https://x.com/nexotips"
            target="_blank"
          >
            <img
              src="./../../../assets/icons/twitter.png"
              alt="twitter"
              class="footer-icon"
            />
            <p class="mb-0">Twitter</p>
          </a>
        </div>
      </div>
      <div class="m-md-auto">
        <div class="copyright">
          © Copyright <strong>Nexotips</strong>. All Rights Reserved. Terms of
          Service and Privacy Policy.
        </div>
      </div>
    </div>
  </div>
</div>
