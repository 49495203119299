<div class="about">
  <div class="container">
    <div
      class="about-section1 d-flex align-items-center justify-content-center"
    >
      <svg
        class="bg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        id="visual"
        viewBox="0 0 900 600"
        width="900"
        height="600"
        version="1.1"
      >
        <g>
          <circle r="139" cx="178" cy="413" />
          <circle r="52" cx="703" cy="261" />
          <circle r="60" cx="421" cy="140" />
        </g>
      </svg>
      <div class="about-text-block" routerLink="">
        <div class="row d-flex flex-md-row flex-column flex-column-reverse">
          <div class="col about-text-img-block px-0">
            <div
              class="till-img-effect d-flex align-items-center justify-content-center"
              #tiltImage
            >
              <img
                src="../../../assets/images/pagenotefound/download-404.svg "
                alt="404 page not found"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
