import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'nex-cookies',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cookies.component.html',
  styleUrl: './cookies.component.scss',
})
export class CookiesComponent {
  cookieValue: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit() {
    // Check if we are in the browser environment
    if (isPlatformBrowser(this.platformId)) {
      const cookieConsent = localStorage.getItem('cookieConsent');
      if (cookieConsent === 'true') {
        this.cookieValue = true;
      }
    }
  }

  cookieHide() {
    if (!this.cookieValue) {
      this.cookieValue = true;
      // Store consent in local storage, but only if in the browser
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem('cookieConsent', 'true');
      }
    }
  }
}
