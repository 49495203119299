<div
  class="CookieConsent position-fixed w-100 bottom-0 text-center"
  [ngClass]="{ cookies_hide: cookieValue }"
>
  <div class="cookies text-center w-100">
    <p class="cookies_text">
      This website uses cookies to ensure you get the best user experience on
      our website.
    </p>
    <button class="cookies_btn" (click)="cookieHide()">I Understand</button>
  </div>
</div>
